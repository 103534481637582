<template>
    <v-container fluid fill-height>
        <v-layout v-if="loading" column align-center pt-5 pb-5>
            <v-progress-circular
                    :size="36"
                    color="primary"
                    indeterminate
            ></v-progress-circular>
            <h2 class="title text--secondary text-xs-center mt-3">{{ loading }}</h2>
        </v-layout>
        <v-layout v-else column pt-2>
            <h1 class="title mb-3">{{ $t('cart.title_confirm_order') }}</h1>
            <span v-if="cart" class="cart-title__wrapper">
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 15 15"><g class="Product" fill="#d2d2d2"><g class="Bttn"><g class="cart-icon"><path class="Fill-5" d="M7.79 2.195L8.094 3.6l4.351.035-1.275 5.042H5.355L2.735 0H.397L.109 1.423h1.685l2.62 8.677h7.667l1.989-7.87z"></path><path d="M5.596 13.551c-.386 0-.7-.363-.7-.81 0-.447.314-.81.7-.81.386 0 .7.363.7.81 0 .447-.314.81-.7.81m0-2.751c-.925 0-1.677.87-1.677 1.94 0 1.07.752 1.941 1.677 1.941s1.677-.87 1.677-1.94c0-1.07-.752-1.941-1.677-1.941" class="Fill-1"></path><path d="M11.05 13.551c-.387 0-.701-.363-.701-.81 0-.447.314-.81.7-.81.387 0 .701.363.701.81 0 .447-.314.81-.7.81m0-2.751c-.925 0-1.678.87-1.678 1.94 0 1.07.753 1.941 1.677 1.941.925 0 1.678-.87 1.678-1.94 0-1.07-.753-1.941-1.678-1.941" class="Fill-3"></path></g></g></g></svg>
              <span class="cart-title__content">{{ cart.name }}</span>
            </span>
            <template v-if="cart">
                <div v-if="cart.lines.lbx.length" class="cart-group">
                    <v-cart-item v-for="item in cart.lines.lbx"
                                 :key="parseInt(item.id)"
                                 :item="item"
                                 :read-only="true"
                    ></v-cart-item>
                </div>
                <div v-if="cart.lines.tax.length" class="cart-group">
                    <v-cart-item v-for="item in cart.lines.tax"
                                 :key="parseInt(item.id)"
                                 :item="item"
                                 :read-only="true"
                    ></v-cart-item>
                </div>
                <div v-if="cart.lines.tbx.length" class="cart-group">
                    <v-cart-item v-for="item in cart.lines.tbx"
                                 :key="parseInt(item.id)"
                                 :item="item"
                                 :read-only="true"
                    ></v-cart-item>
                </div>
                <div v-if="cart.lines.regular.length" class="cart-group">
                    <v-cart-item v-for="item in cart.lines.regular"
                                 :key="parseInt(item.id)"
                                 :item="item"
                                 :read-only="true"
                    ></v-cart-item>
                </div>
                <v-layout v-if="cart.total >= 0" wrap class="c-cart--total mb-2">
                    <v-flex xs6>
                        <div class="title">{{ $t('cart.total') }}</div>
                        <div class="caption">{{ $t('cart.excl_tax') }}</div>
                    </v-flex>
                    <v-flex xs6 pt-1>
                        <h2 class="text-xs-right">{{ cart.total | currency }}</h2>
                    </v-flex>
                </v-layout>
                <v-layout wrap class="c-cart--delivery mb-2">
                    <v-flex xs12 v-if="delivery.date != ''">
                        <p>{{ $t('cart.delivery_text') }}<strong class="c-cart--delivery__date">{{ delivery.date | moment('DD-MM-YYYY') }}</strong>.</p>
                    </v-flex>
                    <v-flex v-if="delivery && delivery.address" class="c-cart--delivery__address" xs12>
                        <p>{{ $t('cart.delivery_address__title') }}</p>
                        <p class="font-weight-bold">{{ delivery.address.name }}</p>
                        <p class="font-weight-bold">{{ delivery.address.address }}</p>
                        <p class="font-weight-bold">{{ delivery.address.zipcode }} {{ delivery.address.city }}</p>
                    </v-flex>
                </v-layout>
                <v-layout  v-if="salesConditionsLink" wrap class="c-cart--accept-conditions mb-2 pl-1 pr-1">
                  <v-checkbox
                    v-model="salesConditionsCheckbox"
                  >
                    <template v-slot:label>
                      <span v-html="$t('legal.salesConditionsCheckbox', {salesConditionsLink: salesConditionsLink})"></span>
                    </template>
                  </v-checkbox>
                </v-layout>
            </template>

            <v-btn v-if="(salesConditionsLink && salesConditionsCheckbox) || !salesConditionsLink" color="secondary" depressed large @click.native="proceedToCheckout">{{ $t('cart.btn_confirm_order') }}</v-btn>
            <v-btn v-if="salesConditionsLink && !salesConditionsCheckbox" class="disabled" color="grey lighten-3" depressed large @click.native="proceedToCheckout">{{ $t('cart.btn_confirm_order') }}</v-btn>

            <v-btn color="grey lighten-3" depressed large :to="{ name: 'cart' }">{{ $t('global.cancel') }}</v-btn>
        </v-layout>
    </v-container>
</template>

<script>
import VCartItem from '@/components/CartItem.vue';
import { mapState } from 'vuex';

export default {
  components: {
    VCartItem,
  },
  data() {
    return {
      loading: false,
      salesConditionsCheckbox: false,
      checkbox: true,
    };
  },
  created() {
    this.$store.dispatch('setLoading', true);
    this.loading = this.$t('cart.prepare_cart_loading');

    // PREPARE CART FOR ORDER
    this.$store.dispatch('prepareCart').then(() => {
      this.loading = false;
      this.$store.dispatch('setLoading', false);
    });
  },
  computed: {
    ...mapState(['personality']),
    cart() {
      return this.$store.state.cart;
    },
    delivery() {
      return this.$store.state.delivery;
    },
    salesConditionsLink() {
      const linkLabel = this.$t('legal.salesConditionsLinkLabel');
      const linkUrl = this.personality.legalLinks?.salesConditions;

      if (linkUrl) {
        return `<a href="${linkUrl}" target="_blank">${linkLabel}</a>`;
      }
      return null;
    },
  },
  methods: {
    proceedToCheckout() {
      if (this.salesConditionsLink && !this.salesConditionsCheckbox) {
        this.$store.dispatch('setAlert', {
          message: this.$t('cart.message.sales_conditions_not_accepted'),
          color: 'error',
        });
        return;
      }
      this.$store.dispatch('setLoading', true);
      this.loading = this.$t('cart.prepare_cart_loading');
      this.$store.dispatch('checkoutCart').then(() => {
        this.loading = false;
        this.$store.dispatch('setLoading', false);
        this.$router.push({ name: 'cart-finished' }).catch(() => {});

        const gtmProducts = [];
        // Get GTM PRODUCTS for TAG MANAGER
        Object.keys(this.cart.lines).forEach((line) => {
          this.cart.lines[line].forEach((item) => {
            let dimension1 = null;
            if (item.damageReportType === 'DMG_C') {
              dimension1 = 'claim - user';
            } else if (item.damageReportType === 'DMG_VH') {
              dimension1 = 'claim - company';
            } else {
              dimension1 = 'order';
            }

            gtmProducts.push({
              name: item.name,
              id: item.id,
              price: item.unitPrice,
              brand: 'Van Hoecke',
              category: line,
              variant: item.variantId,
              quantity: item.quantity,
              dimension1,
            });
          });
        });

        // SEND TRANSACTION DATA TO TAG MANAGER
        this.$gtm.trackEvent({
          ecommerce: {
            purchase: {
              actionField: {
                id: this.cart.id,
                affiliation: 'PWA - Scanner',
                revenue: this.cart.total,
              },
              products: gtmProducts,
            },
          },
          event: 'transaction',
          external_ip: this.personality.external_ip,
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
    .c-cart--delivery {
        padding: 15px 15px;
        p { margin-bottom: 0.3em; }
        &__address {
            p { margin-bottom: 0.1em; }
        }
        &__date {
            white-space: nowrap;
        }
    }
    .cart-title__wrapper {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      column-gap:10px;
      padding-right: 10px;
      font-weight: bold;

      svg {
        flex: 0 0 20px;
      }

      .cart-title__content {
        flex: 1 1 auto;
      }
    }

    /deep/ button.disabled {
      color: #a1a1a1;
      cursor: default;
    }
</style>
